<template>
  <div>
    <div v-if="isCompaniesLoaded" class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
          <div class="card-body">
            <div v-if="isCompaniesLoaded" class="form-group mb-0">
              <vSelect
                v-model="selectedCompany"
                @input="onCompanySelect"
                class="form-control form-control-solid form-control--company-select"
                placeholder="Select company"
                :options="companies"
                label="name"
              >
                <template #option="{ name }">
                  <span class="mr-3">{{ name }}</span>
                </template>
              </vSelect>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-12 text-center">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
      </div>
    </div>

    <div v-if="isCompanySelected" class="row">
      <div class="col-md-3">
        <div class="card card-custom">
          <div class="card-body">
            <DatePicker
              ref="picker"
              v-model="range"
              :attributes="attributes"
              :masks="masks"
              locale="hr"
              is-range
              is24hr
              is-expanded
              :columns="$screens({ default: 1, lg: 1 })"
              :max-date="new Date()"
              :min-date="new Date('12/1/21')"
            >
            </DatePicker>
            <b-button
              v-if="!isTransactionsLoading"
              @click="onGenerate"
              :disabled="!range.start || !range.end || isTransactionsLoading"
              variant="primary"
              class="w-100 mt-6"
              >Generiraj</b-button
            >
            <b-button v-else disabled variant="primary" class="w-100 mt-6"
              ><b-spinner small
            /></b-button>
          </div>
        </div>
      </div>
      <div v-if="isAllLoaded && !isTransactionsLoading" class="col-md-9">
        <div class="row">
          <div class="col-md-6">
            <div
              class="card card-custom bg-light-primary cursor-pointer animate__animated animate__fadeIn mb-8"
            >
              <div class="card-body d-flex align-center">
                <div class="mr-5">
                  <span
                    class="svg-icon svg-icon-3x svg-icon-success animate__animated animate__fadeIn animate__delay-1s"
                  >
                    <inline-svg src="media/svg/icons/Shopping/Dollar.svg" />
                  </span>
                </div>
                <div>
                  <div class="text-dark font-weight-bolder font-size-h2">
                    {{ $t('MAIN_MENU.TRANSACTIONS') }}
                  </div>
                  <div>
                    <span
                      class="text-dark mb-1 font-size-lg animate__animated animate__fadeIn animate__delay-1s"
                      ><strong>Coin: </strong>
                      {{ transactionsCoinNumber }}</span
                    >
                  </div>
                  <div>
                    <span
                      class="text-dark mb-1 font-size-lg animate__animated animate__fadeIn animate__delay-1s"
                      ><strong>SMS: </strong> {{ transactionsSmsNumber }}</span
                    >
                  </div>
                  <div>
                    <span
                      class="text-dark mb-1 font-size-lg animate__animated animate__fadeIn animate__delay-1s"
                      ><strong>Card: </strong>
                      {{ transactionsPayCardNumber }}</span
                    >
                  </div>
                  <div>
                    <span
                      class="text-dark mb-1 font-size-lg animate__animated animate__fadeIn animate__delay-1s"
                      ><strong>Rf Card: </strong>
                      {{ transactionsRfCardNumber }}</span
                    >
                  </div>
                  <div>
                    <span
                      class="text-dark mb-1 font-size-lg animate__animated animate__fadeIn animate__delay-1s"
                      ><strong>SCI: </strong>
                      {{ transactionsPaySciNumber }}</span
                    >
                  </div>
                  <div>
                    <span
                      class="text-dark mb-1 font-size-lg animate__animated animate__fadeIn animate__delay-1s"
                      ><strong>Exec: </strong>
                      {{ transactionsPayExecNumber }}</span
                    >
                  </div>
                  <div>
                    <span
                      class="text-dark mb-1 font-size-lg animate__animated animate__fadeIn animate__delay-1s"
                      ><strong>CCTalk: </strong>
                      {{ transactionsPayCctalkNumber }}</span
                    >
                  </div>
                  <div>
                    <span
                      class="text-dark mb-1 font-size-lg animate__animated animate__fadeIn animate__delay-1s"
                      ><strong>MDB: </strong>
                      {{ transactionsPayMdbNumber }}</span
                    >
                  </div>

                  <div>
                    <span
                      class="text-dark mb-1 mt-4 font-size-lg animate__animated animate__fadeIn animate__delay-1s"
                      ><strong>Total: </strong> {{ totalTransactions }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div
              class="card card-custom bg-light-primary cursor-pointer animate__animated animate__fadeIn mb-8"
            >
              <div class="card-body d-flex align-center">
                <div class="mr-5">
                  <span
                    class="svg-icon svg-icon-3x svg-icon-success animate__animated animate__fadeIn animate__delay-1s"
                  >
                    <inline-svg src="media/svg/icons/Shopping/Dollar.svg" />
                  </span>
                </div>
                <div>
                  <div class="text-dark font-weight-bolder font-size-h2">
                    {{ $t('MAIN_MENU.BILLS') }}
                  </div>
                  <div>
                    <span
                      class="text-dark mb-1 font-size-lg animate__animated animate__fadeIn animate__delay-1s"
                      ><strong>Total: </strong> {{ billsNumber }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import vSelect from 'vue-select'
import ApiService from '../../../core/services/api.service'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import axios from 'axios'
import format from 'date-fns/format'

export default {
  name: 'TransactionsBills',
  components: {
    vSelect,
    DatePicker,
  },
  computed: {
    totalTransactions: function () {
      return (
        this.transactionsCoinNumber +
        this.transactionsSmsNumber +
        this.transactionsPaySciNumber +
        this.transactionsPayExecNumber +
        this.transactionsPayCctalkNumber +
        this.transactionsPayMdbNumber +
        this.transactionsRfCardNumber +
        this.transactionsPayCardNumber
      )
    },
  },
  data() {
    return {
      companies: [],
      selectedCompany: null,
      isCompaniesLoaded: false,
      isCompanySelected: false,
      range: {
        start: null,
        end: null,
      },
      masks: {
        input: 'dd.MM.yyyy - HH:mm:ss',
      },
      attributes: [
        {
          key: 'today',
          highlight: true,
          dates: null,
        },
      ],
      transactionsCoinNumber: 0,
      transactionsSmsNumber: 0,
      transactionsPaySciNumber: 0,
      transactionsPayExecNumber: 0,
      transactionsPayCctalkNumber: 0,
      transactionsPayMdbNumber: 0,
      transactionsRfCardNumber: 0,
      transactionsPayCardNumber: 0,
      billsNumber: 0,
      isAllLoaded: false,
      isTransactionsLoading: false,
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: 'Transactions Bills Comparison',
        route: '/transactions-bills/comparison',
      },
    ])

    ApiService.get('companies', '?pagination=false').then(({ data }) => {
      data['hydra:member'].forEach((company) => {
        this.companies.push(company)
      })
      this.isCompaniesLoaded = true
    })
  },
  methods: {
    onCompanySelect() {
      this.isCompanySelected = true
      this.isAllLoaded = false
    },
    onGenerate() {
      this.isTransactionsLoading = true
      const after = format(new Date(this.range.start), 'yyyy-MM-dd') + ' 00:00:00'
      const before = format(new Date(this.range.end), 'yyyy-MM-dd') + ' 23:59:59'

      ApiService.get(
        'transactions-new',
        `?oib=${this.selectedCompany.oib}&paymentType=pay_card&createdAt[after]=${after}&createdAt[before]=${before}&order[createdAt]=desc&pagination=false`
      ).then(({ data }) => {
        this.transactionsPayCardNumber = data.totalItems

        ApiService.get(
          'transactions-new',
          `?oib=${this.selectedCompany.oib}&paymentType=pay_coin&createdAt[after]=${after}&createdAt[before]=${before}&order[createdAt]=desc&pagination=false`
        ).then(({ data }) => {
          this.transactionsCoinNumber = data.totalItems

          ApiService.get(
            'transactions-new',
            `?oib=${this.selectedCompany.oib}&paymentType=pay_sci&createdAt[after]=${after}&createdAt[before]=${before}&order[createdAt]=desc&pagination=false`
          ).then(({ data }) => {
            this.transactionsPaySciNumber = data.totalItems

            ApiService.get(
              'transactions-new',
              `?oib=${this.selectedCompany.oib}&paymentType=pay_exec&createdAt[after]=${after}&createdAt[before]=${before}&order[createdAt]=desc&pagination=false`
            ).then(({ data }) => {
              this.transactionsPayExecNumber = data.totalItems

              ApiService.get(
                'transactions-new',
                `?oib=${this.selectedCompany.oib}&paymentType=pay_cctalk&createdAt[after]=${after}&createdAt[before]=${before}&order[createdAt]=desc&pagination=false`
              ).then(({ data }) => {
                this.transactionsPayCctalkNumber = data.totalItems

                ApiService.get(
                  'transactions-new',
                  `?oib=${this.selectedCompany.oib}&paymentType=pay_mdb&createdAt[after]=${after}&createdAt[before]=${before}&order[createdAt]=desc&pagination=false`
                ).then(({ data }) => {
                  this.transactionsPayMdbNumber = data.totalItems

                  ApiService.get(
                    'transactions-new',
                    `?oib=${this.selectedCompany.oib}&paymentType=pay_sms&createdAt[after]=${after}&createdAt[before]=${before}&order[createdAt]=desc&pagination=false`
                  ).then(({ data }) => {
                    this.transactionsSmsNumber = data.totalItems

                    ApiService.get(
                      'transactions-new',
                      `?oib=${this.selectedCompany.oib}&paymentType=pay_rfcard&createdAt[after]=${after}&createdAt[before]=${before}&order[createdAt]=desc&pagination=false`
                    ).then(({ data }) => {
                      this.transactionsRfCardNumber = data.totalItems

                      axios
                        .get(
                          `${process.env.VUE_APP_BILLS_API_URL}/api/v1/bills?oib=${this.selectedCompany.oib}&createdAt[after]=${after}&createdAt[before]=${before}&order[createdAt]=desc&pagination=false`
                        )
                        .then(({ data }) => {
                          this.billsNumber = data['hydra:totalItems']

                          this.isAllLoaded = true
                          this.isTransactionsLoading = false
                        })
                    })
                  })
                })
              })
            })
          })
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import 'vue-select/src/scss/vue-select.scss';

.form-control {
  &--company-select {
    height: unset !important;

    .vs__dropdown-toggle {
      padding: 0;
      border: 0;
      border-radius: 0;
    }
    .vs__dropdown-menu {
      border: 1px solid #e4e6ef;
      -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
      box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);

      /* width */
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      li {
        padding: 0.65rem 1rem;

        &:hover {
          background: #f3f6f9;
          color: #3f4254;
        }
      }
    }

    .vs__clear {
      display: none;
    }
    .vs__selected {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      margin: 0.1rem 0.4rem 0.1rem 0;
      position: relative;
      float: left;
      color: #3f4254;
      background: #f3f6f9;
      border: 0;
      border-radius: 0.42rem;
    }
    .vs__dropdown-option--highlight {
      background: #ebedf3;
      color: #3f4254;
    }
  }
}
</style>
